@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #fff;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.h2tags {
  font-size: 28px;
  font-weight: 600;
}

.butn {
  background: linear-gradient(180deg, #fba343, #ef1784);
  font-size: 24px;
  font-weight: 700;
  border: 0;
  color: #fff;
  border-radius: 35px;
  padding: 8px 35px;
  margin: 10px 0;
}

.butn:focus {
  box-shadow: none;
}

.butn:hover {
  color: #fff;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #27145a;
}

*::-webkit-scrollbar-thumb {
  background-color: #130638;
}

.home {
  overflow: hidden;
  background: #0f0233;
}
.banner {
  background: url("./assets/images/banbg.png") no-repeat center;
  background-size: cover;
  padding-top: 170px;
  padding-bottom: 460px;
  position: relative;
}
.banner::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/banbig_bubble.png") no-repeat center;
  width: 225px;
  height: 225px;
  right: 20%;
  top: 0;
  animation: animateBubble 40s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
}

@keyframes animateBubble {
  0% {
    left: calc(100% - 225px);
  }
  50% {
    left: 0%;
  }
  100% {
    left: calc(100% - 225px);
  }
}
@keyframes sideWays {
  0% {
    margin-top: 100px;
  }
  50% {
    margin-top: 0px;
  }
  75% {
    margin-top: 25px;
  }
  100% {
    margin-top: 60px;
  }
}

.banner::after {
  content: "";
  position: absolute;
  background: url("../src/assets/images/banmd_bubble.png") no-repeat center;
  width: 178px;
  height: 178px;
  /* left: 20%; */
  top: 30%;
  animation: animateBubble1 35s linear infinite,
    sideWays1 4s ease-in-out infinite alternate;
  animation-direction: alternate;
}

@keyframes animateBubble1 {
  0% {
    right: calc(100% - 178px);
  }
  50% {
    right: 0%;
  }
  100% {
    right: calc(100% - 178px);
  }
}
@keyframes sideWays1 {
  0% {
    margin-top: -40px;
  }
  50% {
    margin-top: -50px;
  }
  75% {
    margin-top: 55px;
  }
  100% {
    margin-top: 90px;
  }
}

.bannerbg {
  position: relative;
}
.bannerbg::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/bansm_bubble.png") no-repeat center;
  width: 87px;
  height: 87px;
  left: 20%;
  top: 55%;
  animation: animateBubble2 32s linear infinite,
    sideWays2 7s ease-in-out infinite alternate;
}
@keyframes animateBubble2 {
  0% {
    left: calc(100% - 87px);
  }
  50% {
    left: 0%;
  }
  100% {
    left: calc(100% - 87px);
  }
}
@keyframes sideWays2 {
  0% {
    margin-top: -20px;
  }
  50% {
    margin-top: -30px;
  }
  75% {
    margin-top: 65px;
  }
  100% {
    margin-top: 110px;
  }
}

.banner .col-md-6 {
  z-index: 2;
}
.banner h5 {
  color: #ffb200;
  font-size: 24px;
  font-weight: 600;
}

.banner h5 span {
  font-size: 70px;
  color: #fff;
  font-weight: 800;
  margin-right: 10px;
}
.banner .img_box {
  text-align: right;
  margin-right: 50px;
  z-index: 2;
  position: relative;
}

.banner_box {
  border-radius: 50% 50% 0 0;
  background: #0f0233 url("../src/assets/images/bannerbox_bg.png") no-repeat
    center;
  margin-top: -287px;
  position: relative;
  z-index: 1;
  padding-bottom: 30px;
}
.banner_box .col-lg-8 {
  position: relative;
}
.banner_box .curve {
  border-radius: 50% 50% 0 0;
  background: #0f0233 url("../src/assets/images/bannerbox_bg.png") no-repeat
    center;
  width: 103%;
  height: 500px;
  position: absolute;
  z-index: -1;
  margin-top: -50px;
  left: -12px;
}
.banner_box .box {
  border-radius: 40px 10px;
  overflow: hidden;
  border: 2px solid #5f28d1;
  box-shadow: 0 0 10px 0 #5f28d1;
  margin-top: -200px;
}
.banner_box .box::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/banstar.png") no-repeat center;
  width: 400px;
  height: 180px;
  top: -309px;
  right: 0;
  z-index: -1;
  background-size: contain;
}
.banner_box .abv_box {
  background: linear-gradient(53deg, #7062dd, #5a4bcc);
  padding: 20px 30px;
  position: relative;
  padding-right: 50px;
}

.banner_box .abv_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/abv_bg.png") no-repeat right;
  width: 223px;
  height: 148px;
  top: 0;
  right: 0;
  bottom: 0;
}

.banner_box .abv_box .row {
  align-items: center;
}

.banner_box .abv_box h2 {
  font-size: 36px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 25px;
}

.banner_box .abv_box h5 {
  font-size: 20px;
  color: #ffb200;
  font-weight: 600;
}

.banner_box .box .flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1b0c46;
  padding: 10px 20px;
}

.banner_box .box .flx h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.banner_box .box .flx p {
  margin-bottom: 0;
}

.banner_box .box .price_box {
  background: #130636;
  padding: 20px;
}

.banner_box .box .price_box .flex {
  display: flex;
  align-items: center;
}

.banner_box .box .price_box .flex p {
  width: 150px;
}

.banner_box .box .price_box .flex span {
  color: #797979;
  font-weight: 500;
}

.banner_box .box .price_box h2 {
  color: #0a4bc0;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
}

.banner_box .box .price_box .flex .butn {
  font-size: 18px;
  padding: 15px 30px;
  text-transform: uppercase;
}
.btn_grps button+button {
  margin-left: 10px;
}
.banner_box .box .price_box .img_box {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.banner_box .box .accordion-button {
  background: #130636;
  color: #ffb200;
  font-size: 18px;
  box-shadow: none !important;
  font-weight: 600;
  justify-content: center;
}

.banner_box .box .accordion-button::after {
  margin-left: unset;
}
.banner_box .box .accordion-button span {
  border-bottom: 1px solid #ffb200;
}
.banner_box .accordion-button.collapsed .hide {
  display: none;
}
.banner_box .accordion-button:not(.collapsed) .hide {
  display: block;
}
.banner_box .accordion-button.collapsed .details {
  display: block;
}
.banner_box .accordion-button:not(.collapsed) .details {
  display: none;
}

.banner_box .accordion-button:not(.collapsed)::after {
  background: url("../src/assets/images/uparw.png") no-repeat center;
  transform: revert;
  margin-left: 5px;
}
.banner_box .accordion-button.collapsed::after {
  background: url("../src/assets/images/uparw.png") no-repeat center;
  margin-left: 5px;
  transform: rotate(180deg);
}

.banner_box .box .accordion-item {
  background: #190a41;
}
.banner_box .box .accordion-body {
  background: #190a41;
  border-top: 1px solid #2e1a62;
}
.banner_box .box .accordion-body p {
  font-size: 14px;
}
.banner_box .box .accordion .inbox p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.banner_box .accordion .inbox {
  margin: 15px 0;
}
.banner_box .accordion .inbox h5 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffb200;
}
.banner_box .accordion .inbox span {
  color: #797979;
  font-weight: 600;
  font-size: 14px;
  display: block;
}
.banner_box .connect_box {
  text-align: center;
  margin-top: 30px;
}

.banner_box .connect_box p {
  font-size: 28px;
  color: #f9f8fb;
  font-weight: 600;
}
.banner_box .connect_box .butn {
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  padding: 15px 30px;
}
.banner_box .connect_box .butn img {
  margin-right: 10px;
}

.timer_panel {
  display: flex;
  justify-content: center;
  text-align: center;
}

.timer_time {
  font-size: 60px;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}

span.timer_label {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #c5befe;
  margin-top: -5px;
  font-weight: 400;
  text-transform: uppercase;
}

span.timer_dots {
  font-size: 48px;
  color: #ffffff;
  line-height: 82px;
}

.timer_panel > span {
  margin: 0px 5px 0 0;
}

.finished_rounds {
  background: #120538;
  border-radius: 0;
  padding: 0px;
}
.finished_rounds .head {
  text-align: center;
  background: url("../src/assets/images/finish_roundbg.png") no-repeat center;
  padding: 40px 0 30px;
  background-position: top;
}
.finished_rounds .nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff !important;
  border: 0;
  padding: 10px 35px;
}
.finished_rounds .nav-tabs .nav-link.active {
  background-color: #5d27fd;
  border-radius: 30px;
}
.finished_rounds .nav-tabs {
  display: inline-flex;
  background: #180748;
  border-radius: 30px;
  border: 0;
  margin: 0 auto;
}
.finished_rounds .box::before {
  display: none;
}
.finished_rounds .a_box {
  background: #1f0a58;
  padding: 30px 40px 10px;
}
.finished_rounds .a_box h6 {
  font-size: 18px;
  font-weight: 600;
}
.finished_rounds .a_box h6 span {
  font-size: 16px;
  background: #5545cc;
  border-radius: 20px;
  padding: 5px 15px;
  margin-left: 10px;
}
.finished_rounds .a_box p {
  font-size: 14px;
  color: #ffb200;
}
.finished_rounds .arrows {
  display: flex;
  justify-content: flex-end;
}
.finished_rounds .arrows .arrow {
  padding: 5px;
}
.finished_rounds .arrows .arrow.active {
  filter: invert(69%) sepia(6%) saturate(4645%) hue-rotate(185deg)
    brightness(201%) contrast(101%);
}
.finished_rounds .flx {
  background: #392ba6 !important;
  padding: 25px 40px !important;
}
.finished_rounds .numbers {
  display: flex;
}
.finished_rounds .numbers .round {
  width: 48px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
  margin: 0 5px;
  -webkit-text-stroke: 1px #00000085;
  font-family: sans-serif;
}
.finished_rounds .numbers .round1 {
  background: url("../src/assets/images/numbg1.png") no-repeat center;
}
.finished_rounds .numbers .round2 {
  background: url("../src/assets/images/numbg2.png") no-repeat center;
}
.finished_rounds .numbers .round3 {
  background: url("../src/assets/images/numbg3.png") no-repeat center;
}
.finished_rounds .numbers .round4 {
  background: url("../src/assets/images/numbg4.png") no-repeat center;
}
.finished_rounds .numbers .round5 {
  background: url("../src/assets/images/numbg5.png") no-repeat center;
}
.finished_rounds .numbers .round6 {
  background: url("../src/assets/images/numbg6.png") no-repeat center;
}
.finished_rounds .box {
  box-shadow: -1px 0px 13px -4px #5f28d1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 40px;
  margin-bottom: 60px !important;
}
.finished_rounds .box .accordion-button,
.finished_rounds .box .accordion-header {
  background: #120339;
}

.finished_rounds .box .left_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.finished_rounds .box .price_box {
  background: none;
  padding: 0;
}
.finished_rounds .box .price_box .flex {
  align-items: flex-start;
  flex-direction: column;
}
.banner_box .box .price_box .flex p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.finished_rounds .box .total {
  margin-bottom: 47px;
}
.finished_rounds .box .total p {
  font-size: 14px;
  margin-bottom: 0;
}
.finished_rounds .accordion .inbox span {
  font-size: 13px;
}
.finished_rounds .accordion .inbox h5 {
  font-size: 23px;
  margin-bottom: 10px;
}
.finished_rounds table {
  border-color: #210e5a;
}
.finished_rounds tbody {
  border: 1px solid #210e5a !important;
}
.finished_rounds td:nth-child(1),
.finished_rounds th:nth-child(1) {
  padding-left: 35px;
}
.finished_rounds th {
  color: #ffb200;
  border-color: rebeccapurple;
  border-bottom: 2px solid #5f28d1;
  padding-top: 15px;
}
.finished_rounds td {
  color: #fff;
  border-color: #1b0a4e;
}


.yourhistory table{
 vertical-align: middle;
}
.yourhistory table button{
  margin: 0;
  font-size: 18px;
}



.howplay .howplaybg {
  padding: 60px 0 0;
  background: #0f0233;
  text-align: center;
}
.howplay .curve {
  width: 100%;
  height: 100px;
  border-radius: 0 0 50% 50%;
  background: #0f0233;
  z-index: 2;
  position: relative;
}
.howplay .howplaybg h5 {
  color: #ffb200;
  font-size: 24px;
  font-weight: 600;
}
.howplay .howplaybg h4 {
  color: #0a4bc0;
  font-size: 22px;
  font-weight: 600;
}
.howplay .content {
  display: none;
}
.winning {
  background: #120538;
  margin-top: -70px;
  padding: 125px 0;
  position: relative;
  z-index: 1;
  border-radius: 0 0 34% 32%;
  overflow: hidden;

  border-radius: 0;
  padding-bottom: 0;
}
/* .winning .winningbg{
 background: #120538;
 margin-top: -70px;
 padding: 125px 0;
 position: relative;
 z-index: 1;
} */
.winning.curve {
  width: 100%;
  height: 100px;
  border-radius: 0 0 50% 50%;
  background: #120538;
  z-index: 2;
  position: relative;
  padding: 0;
  margin: 0;
}
.winning .head p {
  font-size: 18px;
  font-weight: 500;
}
.winning p {
  color: #f1f1f1;
}

.winning ul {
  list-style-type: none;
}
.winning li {
  position: relative;
}
.winning li::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/right_arw.png") no-repeat center;
  width: 18px;
  height: 12px;
  left: -30px;
  top: 6px;
}
.winning li span {
  color: #ffe046;
}
.winning .img_box {
  margin-left: 30px;
}
.winning .img_box::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 403px;
  background: #0c0229;
  top: 0;
  z-index: -1;
  padding: 42px;
  margin-left: -45px;
  bottom: 0;
}
.prize {
  background: #0f0233;
  margin-top: -47px;
  padding: 100px 0;
  position: relative;
  z-index: 0;
  border-radius: 0;
}
.prize p span {
  display: block;
  color: #ffe046;
}
.prize p a {
  color: #ffe046;
}
.prize .img_box {
  width: 50%;
  margin: 0 auto;
}
.prize ul {
  padding-left: 0;
}
.prize li {
  display: flex;
  justify-content: space-between;
}
.prize li::before {
  display: none;
}
.prize li p {
  margin-bottom: 0;
}
.prize li:nth-child(1) p {
  color: #ffe046;
  margin-bottom: 15px;
}
.prize li .dot {
  padding-left: 20px;
  position: relative;
}
.prize li .dot::before {
  content: "";
  background: #ffe362;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0px;
  border-radius: 50%;
  top: 6px;
}
.prize li:nth-child(3) .dot::before {
  background: #85c54e;
}
.prize li:nth-child(4) .dot::before {
  background: #028e75;
}
.prize li:nth-child(5) .dot::before {
  background: #36e8f5;
}
.prize li:nth-child(6) .dot::before {
  background: #a881fc;
}
.prize li:nth-child(7) .dot::before {
  background: #d750b2;
}
.prize li:nth-child(8) .dot::before {
  background: #bdc2c4;
}

.question {
  padding: 50px 0;
  text-align: center;
  background: url("../src/assets/images/question.png") no-repeat center;
  background-size: cover;
}
.question p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
.question a {
  color: #ffe046;
  text-decoration: underline;
}

.footer {
  background-color: #0d032b;
  padding: 30px 0 30px;
}

.footer_panel_top {
  text-align: center;
  border-bottom: 0px solid #063554;
  padding-bottom: 20px;
}

.footer_panel_top h2 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.footer_panel_top p {
  font-size: 16px;
  max-width: 50%;
  margin: 0 auto 30px;
  color: #f1f1f1;
}

.footer_panel_top .input-group {
  width: 50%;
  margin: 0 auto 25px;
  padding: 5px;
}

.footer_panel_top .input-group input {
  background: #ffffff;
  color: #1e1e1e;
  font-size: 18px;
  height: 65px;
  border-radius: 30px;
  padding-left: 30px;
  box-shadow: none !important;
  border: 0;
}

.footer_panel_top .input-group input::placeholder {
  color: #1e1e1e;
  font-size: 18px;
}

.footer_panel_top .input-group-text {
  text-transform: uppercase;
  font-size: 20px;
  color: #f1eef5;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 30px;
  background: linear-gradient(90deg, #fba343, #ef1784);
  border: 0;
}

.footer_social_links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}

.footer_social_links li {
  margin: 0 5px;
}

.footer_social_links li a {
  width: 36px;
  height: 36px;
  background: #3a034e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d032b;
  font-size: 19px;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: #f12e79;
  color: #0d032b;
}

.footer_panel_bottom {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #063554;
}

.footer_list_links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.footer_list_links li {
  position: relative;
}
.footer_list_links li + li {
  margin-left: 30px;
}

.footer_list_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: -15px;
}

.footer_list_links li:last-child::after {
  display: none;
}

.footer_list_links li a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

.footer_list_links li a:hover {
  text-decoration: none;
  color: #fff;
}

.footer_panel_bottom p {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
}

.main_navbar {
  background-color: #00162400 !important;
  z-index: 10;
  transition: transform 0.3s;
  padding: 10px 0;
  position: fixed;
  width: 100%;
}
.main_navbar .navbar-brand {
  outline: 0 !important;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: -40px;
  background-color: #300c7a !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 40%);
  z-index: 999;
  transform: translateY(40px);
}
.main_navbar .butn {
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  padding: 12px 20px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 30px;
}
.main_navbar .butn img,.main_navbar .butn svg {
  margin-right: 10px;
}
.main_navbar .navbar-toggler i {
  color: white;
  font-size: 24px;
}
.main_navbar .navbar-toggler:focus {
  box-shadow: none;
}
.menudrop .MuiMenu-list{
  background: #4813b5;
    color: #fff;
}
.menudrop .MuiMenu-list p{
  white-space: normal;
}
.menudrop .MuiMenu-paper {
  transform: translate(-37px,0) !important;
  background: #4813b5;
  overflow: auto;
    width: 250px;
}
/* primary_modal */
.primary_modal .modal-content {
  background: linear-gradient(0deg, #0f0233 0%, rgb(15 4 52) 100%);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #291174;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #291174;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.connect_wallet_button:hover {
  border-color: #4926b7;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.primary_modal .btn-close {
  border: 0;
  outline: none;
  box-shadow: none !important;
  filter: invert(1);
  opacity: 0.8;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}
.buy_modal {
  color: red;
}
.buy_modal .btn-close {
  filter: invert(1);
  opacity: 1;
  box-shadow: none;
}
.buy_modal label {
  display: flex;
  justify-content: space-between;
}

.buy_modal .flx {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.buy_modal .flx p,
.buy_modal label p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ffffff;
}
.buy_modal .flx p span,
.buy_modal label span {
  color: #ffb200;
  font-size: 15px;
}
.buy_modal .flx.brdr {
  border-bottom: 1px solid #242424;
  padding-bottom: 10px;
}
.buy_modal .modal-header {
  background: #0c0229;
  color: #fff;
  border: 0;
  padding: 30px;
}
.buy_modal .modal-body {
  padding: 30px;
}
/* .buy_modal .modal-dialog {
  max-width: 360px;
} */
.buy_modal .modal-content {
  color: #fff;
  background: #120538;
  border-radius: 20px;
  overflow: hidden;
}
.buy_modal .box {
  background: #190a41;
  border-radius: 10px;
}
.buy_modal .box p {
  padding: 10px;
  text-align: right;
  font-size: 14px;
}
.buy_modal input,
.buy_modal input:focus {
  background: transparent;
  border: 0;
  text-align: right;
  box-shadow: none !important;
  color: #fff;
}
.buy_modal .butn {
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  margin: 15px auto;
  display: inherit;
}
.buy_modal .notes {
  font-size: 13px;
  margin-bottom: 0;
}

.prediction {
  padding-bottom: 100px;
}
.prediction .boxes {
  z-index: 2;
  position: relative;
  border: 2px solid #5f28d1;
  box-shadow: 0 0 10px 0 #5f28d1;
  background: #00000075;
  padding: 50px;
  border-radius: 30px;
}
.prediction .boxes .in_box {
  background: linear-gradient(to bottom, #1f0a59, #0f0233);
  border: 2px solid #5f28d1;
  box-shadow: 0 0 10px 0 #5f28d1;
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
  margin: 10px 0;
}
.prediction .boxes .in_box .head {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #392ba6;
}
.prediction .boxes .in_box .head p {
  margin-bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.prediction .boxes .in_box .head i {
  margin-right: 10px;
}
.prediction .boxes .in_box .head span {
  color: #bf61db;
  font-size: 14px;
}
.prediction .boxes .in_box .pr_box {
  background: #220759;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 30px;
  padding: 15px;
  z-index: 1;
  position: relative;
}
.prediction .boxes .in_box .pr_box .greenbtn {
  background: #392ba6;
  color: #fff;
  display: inline-block;
  border-radius: 20px;
  padding: 4px 15px;
  font-size: 14px;
  margin-bottom: 8px;
}
.prediction .boxes .in_box .pr_box .greenbtn img {
  transform: rotate(-90deg);
  height: 9px;
  margin-right: 3px;
  margin-top: -2px;
}
.prediction .boxes .in_box .pr_box p {
  margin-bottom: 0;
}
.prediction .boxes .in_box .pr_box h5 {
  color: #fcfdcd;
  font-size: 26px;
  font-weight: 700;
}
.prediction .price_boxflx {
  display: flex;
  justify-content: space-between;
}
.prediction .price_boxflx h6 {
  font-size: 20px;
  font-weight: 600;
}
.prediction .price_boxflx .green {
  color: #d1d1f9;
}
.prediction .price_boxflx .coin {
  color: #d1d1f9;
  font-weight: 700;
}
/* .pentagon {
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
} */
.prediction .boxes .arw {
  background: url("../src/assets/images/arrowup.png") no-repeat center;
  height: 123px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: -28px;
  margin-top: 15px;
  /* filter: invert(2%) sepia(6%) saturate(784%) hue-rotate(54deg) brightness(57%) contrast(21%); */
  /* filter: invert(1%) sepia(6%) saturate(685%) hue-rotate(504deg) brightness(7%) contrast(1%); */
  /* background-size: 75% 100%; */
}
.prediction .boxes .arw h5 {
  color: #1e0a56;
  margin-bottom: 0;
}
.prediction .boxes .arw p {
  margin-bottom: 0;
  color: #1e0a56;
}
.prediction .boxes .arw.down {
  background: url("../src/assets/images/downarw.png") no-repeat center;
  margin-top: -28px;
  margin-bottom: 15px;
  z-index: 0;
  position: relative;
  /* filter: invert(8%) sepia(6%) saturate(784%) hue-rotate(54deg) brightness(191%) contrast(0%); */
}

.prediction .boxes .in_box.live .head {
  background: transparent;
}
.prediction .boxes .in_box.live .progress {
  height: 8px;
  background-color: #392ba6;
  border-radius: 0;
}
.prediction .boxes .in_box.live .progress-bar {
  background-color: #00dd80;
}
.prediction .boxes .in_box.expired {
  opacity: 0.7;
}

.predictionbox {
  display: flex;
  justify-content: space-between;
  background: #00000075;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0 20px;
  z-index: 1;
  position: relative;
}
.predictionbox .box {
  display: flex;
  align-items: center;
  color: #bf61db;
  margin: 0 10px;
  border-radius: 30px;
  padding: 10px;
}
.predictionbox .box.active {
  background: #5d27fd;
  color: #fff;
}
.predictionbox p {
  margin-bottom: 0;
}
.predictionbox img,
.predictionbox i {
  margin-right: 10px;
  font-size: 20px;
}
.predictionbox .charts,
.predictionbox .account,
.predictionbox .w_box {
  display: flex;
  align-items: center;
}
.chart  .bannerbg::before{
  display: none;
}
.chart {
  padding-bottom: 100px;
}
.chart .box_flx {
  display: flex;
  border: 2px solid #5f28d1;
  box-shadow: 0 0 10px 0 #5f28d1;
  z-index: 2;
  position: relative;
}
.chart .box_flx .g_box {
  width: 100%;
  margin-right: 5px;
}
.chart .box_flx .g_box img {
  width: 100%;
}
.chart .right_box {
  width: 400px;
  background: #371676;
  border-radius: 5px;
  padding: 8px;
}

.chart .box {
  background: #44257f;
  border-radius: 5px;
  overflow: hidden;
}
.chart .head {
  background: saddlebrown;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  background: linear-gradient(45deg, #5d4295, #523588);
}
.chart h4 {
  margin-bottom: 0;
  font-size: 22px;
}
.chart .right_box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.chart .right_box li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}
.chart .right_box li p {
  margin-bottom: 0;
}
.chart .right_box li span {
  margin-bottom: 0;
  color: #db8895;
}
.chart .right_box li:nth-child(odd) {
  background: rgb(38 39 61);
}
.chart .right_box li:nth-child(even) {
  background: rgb(37 26 57);
}

.chart .right_box li .butn {
  background: rgb(203, 7, 11);
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
}
.chart .right_box li .butn img {
  transform: rotate(90deg);
}
.chart .right_box li .butn.green {
  background: rgb(64, 185, 92);
}
.chart .right_box li .butn.green img {
  transform: rotate(-90deg);
}

.chart .right_box .butn1 {
  width: 100%;
  border-radius: 5px;
}
.chart .right_box .progress {
  height: 8px;
  background-color: #392ba6;
  border-radius: 0;
}
.chart .right_box .progress-bar {
  background-color: #00dd80;
}
.view_tickets_number {
  display: flex;
  justify-content: space-around;
  border: 2px solid #5f28d1;
  box-shadow: 0 0 10px 0 #5f28d1;
  border-radius: 30px;
  padding: 15px;
  margin-bottom: 30px;
}
.view_tickets_number p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
.setting_page{
  background: url("./assets/images/banbg.png") no-repeat center;
  background-size: cover;
  padding-top: 170px;
  padding-bottom: 100px;
}
.setting_page .butn{
  font-size: 18px;
  border-radius: 5px;
  padding: 3px 10px;
}
.setting_page tr:nth-last-child(1) td{
  border: 0;
}
.setting_page .form-control{
    background-color: #3b0b79;
    border: 1px solid #4e2386;
    color: #fff;
    box-shadow: none !important;
    margin-bottom: 15px;
}
.setting_page table{
  color: #fff;
  border-color: #5b2fb2;
  white-space: nowrap;
}
.setting_page tbody{
  border-color: #5b2fb2 !important;
}
.setting_page input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.lottery_list{
  padding-top: 170px;
  padding-bottom: 50px;
  background: url("./assets/images/banbg.png") no-repeat center;
  background-size: cover;
  min-height: 100vh;
}
.lottery_list .table-responsive{
  background: #44208e;
}
.lottery_list .as-react-table{
  background: #44208e;
  padding: 15px;
  color: #fff;
  white-space: nowrap;
}
.lottery_list table{
  color: #fff;
  border-color: #5b2fb2;
}
.lottery_list tbody{
  border-color: #5b2fb2 !important;
}
.lottery_list td{
  color: #fff !important;
  vertical-align: middle;
}
.lottery_list .butn{
  font-size: 16px;
  border-radius: 5px;
  margin: 0;
}
.lottery_list .input-group-addon{
  padding: 6px !important;
  background: #6224e2 !important;
}
.lottery_list .input-group-text{
  color: #fff;
}
.lottery_list  select{
  background: #6224e2;
  color: #fff;
  border: 0;
  box-shadow: none !important;
}
.lottery_list  select:focus{
  background: #6224e2;
  color: #fff;
}
.lottery_list option{
  background: #6224e2;
  color: #fff;
}
.lottery_list .page-item.disabled .page-link{
  background-color: #6831d9;
  color: #939393;
  border-color: #7647d7;
}
.lottery_list  .page-link{
  background-color: #6831d9;
  color: #fff;
  border-color: #7647d7;
  box-shadow: none !important;
}
.lottery_list  .page-link input{
  background: rebeccapurple;
  color: #fff;
  box-shadow: 0px 0px 1px 1px rgb(138 104 158);
  outline: 0 !important;
}

@media (max-width: 1399px) {
  .banner_box .box .price_box .flex .butn {
    padding: 15px 25px;
  }
  .banner_box .abv_box {
    padding-left: 20px;
  }
  .prize .img_box {
    width: 61%;
  }
}
@media (max-width: 1199px) {
  .timer_panel {
    justify-content: flex-start;
  }
  .timer_time {
    font-size: 50px;
  }
  span.timer_dots {
    line-height: 70px;
  }
  .banner_box .box .price_box .flex p {
    width: 165px;
  }
  .banner h5 span {
    font-size: 60px;
  }
  .banner_box .abv_box::before {
    width: 100%;
    height: 100%;
    background-size: contain;
  }
  .howplay .curve {
    display: none;
  }

  .howplay .howplaybg {
    padding-bottom: 35px;
  }
  .winning {
    border-radius: 0;
    padding: 50px 0;
    margin-top: 0;
  }
  .winning.curve {
    display: none;
  }
  .prediction .boxes {
    padding: 25px;
  }
  .prediction .boxes .in_box .pr_box h5 {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .banner_box {
    border-radius: 0;
  }
  .banner_box .curve {
    display: none;
  }

  .winning .img_box {
    padding: 20px;
    margin-left: 0;
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    width: 61%;
  }
  .winning .img_box::before {
    width: 100%;
    top: 0;
    z-index: -1;
    margin-left: 0px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .prize {
    padding: 50px 0;
  }
  .prize .row {
    flex-direction: column-reverse;
  }
  .footer_panel_top p {
    max-width: 100%;
  }
  .footer_panel_top .input-group {
    width: 100%;
  }
  .howplay img {
    display: none;
  }
  .howplay .content {
    display: block;
  }
  .predictionbox {
    padding: 0 10px;
  }
  .chart .box_flx {
    flex-direction: column;
  }
  .chart .box_flx .g_box {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .chart .right_box {
    width: 100%;
  }
  .banner_box .box .price_box .img_box {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .banner .row {
    flex-direction: column-reverse;
  }
  .banner .img_box {
    text-align: left;
    margin-top: 30px;
  }
  
  .banner_box .abv_box h2 {
    font-size: 32px;
  }
  .timer_time {
    font-size: 40px;
  }
  span.timer_dots {
    line-height: 53px;
  }
  .finished_rounds .numbers .round {
    width: 40px;
    height: 40px;
    font-size: 25px;
    margin: 0 1px;
    background-size: contain !important;
  }
  .footer_panel_bottom {
    flex-direction: column;
  }
  .footer_list_links {
    margin-bottom: 5px;
  }
  .predictionbox {
    flex-direction: column;
    padding: 10px;
  }
  .predictionbox .box {
    margin: 0;
    margin-right: 5px;
  }
  .predictionbox .w_box {
    margin: 18px 0px;
  }
  .lottery_list  .asrt-pagination{
    justify-content: flex-start !important;
    margin-top:15px !important
  }
}
@media (max-width: 575px) {
  .butn {
    font-size: 18px;
  }
  .banner::before {
    width: 125px;
    height: 125px;
    background-size: contain;
  }
  .bannerbg::before {
    width: 60px;
    height: 60px;
    background-size: contain;
  }
  .banner::after {
    width: 100px;
    height: 100px;
    background-size: contain;
  }
  .banner h5 span {
    font-size: 40px;
  }
  .banner_box .abv_box::before {
    display: none;
  }
  .banner_box .box .flx {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px !important;
  }
  .finished_rounds .numbers {
    margin-top: 15px;
  }
  .banner_box .box .price_box .flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .banner_box .box .price_box .flex .butn {
    margin-bottom: 20px !important;
  }
  .banner_box .connect_box p {
    font-size: 24px;
  }
  .finished_rounds .nav-tabs .nav-link {
    padding: 10px 25px;
    font-size: 16px;
  }
  .finished_rounds .a_box {
    position: relative;
  }
  .finished_rounds .arrows {
    position: absolute;
    top: 21px;
    right: 12px;
  }
  .winning .img_box {
    width: unset;
  }
  .prize li p {
    font-size: 14px;
  }
  .main_navbar {
    background-color: #300c7a !important;
  }
  .main_navbar .butn {
    margin-top: 15px !important;
    font-size: 16px;
  }
  .menudrop .MuiMenu-paper{
    transform: translate(-53px,0) !important;
    margin-top: 22px;
  }
 

  .footer_panel_top .input-group input {
    font-size: 16px;
  }
  .footer_panel_top .input-group-text {
    font-size: 16px;
    padding: 10px;
  }
  .footer_list_links {
    flex-direction: column;
  }
  .footer_list_links li + li {
    margin-left: 0;
  }
  .footer_list_links li::after {
    display: none;
  }
  .prediction .boxes {
    padding: 10px;
  }
  .predictionbox .w_box {
    flex-direction: column;
    align-items: flex-start;
  }
  .prediction .price_boxflx {
    flex-direction: column;
  }
  .prediction .price_boxflx h6 {
    margin-bottom: 0;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
